@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600;1,700&family=Press+Start+2P&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  body {
    @apply font-Montserrat;
  }
  .main-container {
    @apply mx-auto max-w-7xl px-4 sm:px-6;
  }
  .heading-1 {
    @apply text-2xl sm:text-4xl md:text-5xl font-bold font-PressStart2p;
  }
  .heading-2 {
    @apply font-Montserrat text-xl sm:text-3xl font-bold md:text-4xl;
  }

  .heading-3 {
    @apply font-Montserrat text-lg sm:text-2xl font-bold md:text-3xl;
  }
  .heading-4 {
    @apply font-Montserrat text-lg sm:text-xl font-bold md:text-2xl;
  }
  .heading-5 {
    @apply font-Montserrat text-base sm:text-lg font-bold md:text-xl;
  }

  .heading-6 {
    @apply font-Montserrat text-[14px];
  }

  .paragraph-1 {
    @apply font-Montserrat text-lg;
  }
  .paragraph-2 {
    @apply font-Montserrat text-sm;
  }
  .paragraph-3 {
    @apply font-Montserrat text-xs;
  }
}

@font-face {
  font-family: 'Press-Start-2P';
  src: url('../public/fonts/PressStart2P-Regular.ttf');
}
body {
  background-color: black;
}

.truncateText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
/* modal sheet css */
.customModalSheet .react-modal-sheet-container {
  display: table !important;
  height: fit-content !important;
  border-top: 5px solid #e0f752;
  border-top-right-radius: 40px !important;
  border-top-left-radius: 40px !important;
}
.blueModalSheet .react-modal-sheet-container {
  display: table !important;
  height: fit-content !important;
  border-top: 5px solid #e0f752;
  border-top-right-radius: 40px !important;
  border-top-left-radius: 40px !important;
  background-color: blue !important;
}
.nftCardTruncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
